import { loadApp } from './backoffice-client';
import { LoadAppProps } from './interfaces';

interface NPSData {
    isDue: boolean;
    survey: {
        uuid: string;
        name: string;
        is_active: boolean;
        configs: {
            forms: {
                type: 'text-field' | 'rating';
                label: string;
                required: boolean;
            }[];
        };
    };
}

export const nps = {
    async init(config: LoadAppProps) {
        try {
            const apiUrl = `${window.origin}/api/v3/nps-service/due-survey`;
            const npsResponse = await fetch(apiUrl);
            const npsData: NPSData = await npsResponse.json();

            if (npsData.isDue) {
                loadApp('nps-survey', config);
            }
        } catch (err: any) {
            console.warn(`Failed to load nps survey.  ${err.message}`);
        }
    },
};
