/**
 * Base origin URL for CloudFront.
 *
 * @const {string} cloudfrontBaseOrigin - The base URL for CloudFront.
 */
export const cloudfrontBaseOrigin = 'https://d19dyyafqypjp8.cloudfront.net';

/**
 * Determines if a file is a CSS file based on its extension.
 *
 * @param {string} file - The file name or path.
 * @returns {boolean} True if the file has a `.css` extension, false otherwise.
 */
export const isCSS = (file: string) => /\.css$/.test(file);

/**
 * Loads a JavaScript file by creating a script element and appending it to the document.
 *
 * @param {string} url - The URL of the JavaScript file.
 * @param {string} appName - The name of the application, used to set the script ID.
 * @returns {Promise<void>} A promise that resolves when the script has loaded or rejects if there is an error.
 */
export function loadJs(url: string, appName: string) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.id = `bo-script-${appName}`;
        script.type = 'application/javascript';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;

        document.body.appendChild(script);
    });
}

/**
 * Loads a CSS file by creating a link element and appending it to the document.
 *
 * @param {string} url - The URL of the CSS file.
 * @param {string} appName - The name of the application, used to set the link ID.
 * @returns {Promise<void>} A promise that resolves when the CSS has loaded or rejects if there is an error.
 */
export function loadCss(url: string, appName: string) {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.href = url;
        link.id = `bo-style-${appName}`;
        link.rel = 'stylesheet';
        link.onload = resolve;
        link.onerror = reject;

        document.head.appendChild(link);
    });
}

/**
 * Validates the base origin URL to ensure it is a non-empty string.
 *
 * @param {string | undefined} baseOrigin - The base origin URL to validate.
 * @returns {boolean} True if the base origin is a valid, non-empty string, false otherwise.
 */
export const validateBaseOrigin = (baseOrigin: string | undefined) => {
    if (!baseOrigin || baseOrigin === '') return false;
    return true;
};

/**
 * Maps a set of properties to data attributes on an HTML element specified by the selector.
 *
 * This function finds an HTML element using the provided selector, and then iterates over the given properties.
 * For each property, it sets a corresponding `data-` attribute on the found element with the property value.
 *
 * @param {string} selector - The ID of the HTML element to which the data attributes will be added. The ID should not include the `#` symbol.
 * @param {Record<string, string>} props - An object where each key-value pair represents a data attribute name and its value. The attribute name will be prefixed with `data-`.
 *
 * @example
 * // Assuming there is an element with ID 'myElement' in the DOM
 * mapPropsToSelectorAttributes('myElement', { role: 'button', state: 'active' });
 * // This will add data-role="button" and data-state="active" to the element with ID 'myElement'
 */
export function mapPropsToSelectorAttributes(selector: string, props: Record<string, string>) {
    const elem = document.getElementById(selector);
    if (elem) {
        Object.entries(props).forEach(([key, value]) => {
            elem.setAttribute(`data-${key}`, value);
        });
    }
}

/**
 * Subscribes to changes in the properties of the given `attributes` object.
 * The function wraps each property with a getter and setter, allowing a `callback`
 * function to be invoked whenever a property value is updated. The `callback`
 * is called with a new object containing the updated values.
 *
 * @param {Object} attributes - An object whose properties are to be observed for changes.
 * @param {function(Object): void} callback - A function to be called when any property value changes.
 *
 * @example
 * const options = { color: 'red', size: 'large' };
 * subscribeAppOptions(options, (updatedAttributes) => {
 *     console.log('Attributes updated:', updatedAttributes);
 * });
 *
 * options.color = 'blue'; // Logs: Attributes updated: { color: 'blue', size: 'large' }
 * options.size = 'small'; // Logs: Attributes updated: { color: 'blue', size: 'small' }
 */
export function subscribePropertiesState(attributes: { [key: string]: any }, callback: (attr: { [key: string]: any }) => void) {
    Object.entries(attributes).forEach(([key, value]) => {
        let currentValue = attributes[key];
        Object.defineProperty(attributes, key, {
            get() {
                return currentValue;
            },
            set(newValue) {
                if (newValue !== value) {
                    currentValue = newValue;
                    callback({ ...attributes, [key]: currentValue });
                }
            },
        });
    });
}
