// eslint-disable-next-line
import { equals } from 'ramda';
import { LoadAppProps, WebpackStats, WebpackStatsChunksValues } from './interfaces';
import { cloudfrontBaseOrigin, validateBaseOrigin, isCSS, loadCss, loadJs, mapPropsToSelectorAttributes } from './utils';
import CacheAppService from './services/CacheApp';
import { registeredWorker } from './worker';

const cacheService = new CacheAppService();

/**
 * Loads an application by fetching Webpack stats and dynamically loading the necessary assets (JavaScript and CSS).
 * Applies attributes to a targeted DOM element based on provided options.
 *
 * @param {string} appName - The name of the application to load.
 * @param {LoadAppProps} [options={}] - Optional configuration options for loading the app.
 * @throws {Error} Throws an error if the appName is missing.
 */
export async function loadApp(appName: string, options = {} as LoadAppProps) {
    if (!appName) {
        throw Error('Failed to load app from backoffice-client. Missing appName property');
    }

    if (cacheService.hasCache(appName)) {
        const cachedOptions = cacheService.getCache(appName);
        if (equals(cachedOptions, options)) return;
        if (options.appOptions) {
            const { selector, attributes } = options.appOptions;
            mapPropsToSelectorAttributes(selector, attributes);
        }
    } else {
        if (appName === 'i2go-modal') {
            let target = document.getElementById('i2go-modal-root');
            if (!target) {
                target = document.createElement('div');
                target.id = 'i2go-modal-root';
                document.body.appendChild(target);
            }
        }

        if (!cacheService.hasCache('worker')) {
            registeredWorker.forEach((Worker) => {
                (() => new Worker())();
            });
            cacheService.setCache('worker', registeredWorker);
        }

        const { customBaseOrigin, serverEnvironment } = options;
        let url = `${window.origin}/dashboard/webpack-bundles`;

        if (serverEnvironment === 'uat') url = cloudfrontBaseOrigin;
        if (customBaseOrigin) url = validateBaseOrigin(customBaseOrigin) ? customBaseOrigin : url;

        const webpackStats = (await fetch(`${url}/apps/${appName}/webpack-stats.json`).then((res) => res.json())) as WebpackStats;
        const assets: string[] = Object.values(webpackStats.chunks).reduce((acc, curr) => {
            curr.forEach((values: WebpackStatsChunksValues) => acc.push(values.name));
            return acc;
        }, []);

        assets.forEach((asset) => {
            const assetUrl = `${url}/apps/${appName}/${asset}`;

            if (isCSS(asset)) {
                loadCss(assetUrl, appName);
            } else {
                loadJs(assetUrl, appName);
            }
        });

        if (options.appOptions) {
            const { selector, attributes } = options.appOptions;
            mapPropsToSelectorAttributes(selector, attributes);
        }

        cacheService.setCache(appName, options);
    }
}
