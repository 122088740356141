import { I2goModalWorker } from './services/I2goModalWorker';
import { I2goToastWorker } from './services/I2goToastWorker';

interface ModalOptions {
    [key: string]: any;
}

export const registeredWorker = [I2goModalWorker, I2goToastWorker];

export function showI2goModal(modalName: string, options: ModalOptions) {
    const modalWorker = new I2goModalWorker();
    modalWorker.postMessage({ modalName, options: options ?? {} });
}

export function showToastNotification(options: ModalOptions) {
    const modalWorker = new I2goToastWorker();
    modalWorker.postMessage(options);
}
