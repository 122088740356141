class CacheAppService {
    private cache: Map<string, any>;

    constructor() {
        this.cache = new Map<string, any>();
    }

    /**
     * Stores an object in the cache.
     *
     * @param key - The key for the cached object.
     * @param value - The object or value to cache.
     */
    public setCache(key: string, value: any): void {
        if (this.hasCache(key)) return;
        this.cache.set(key, value);
    }

    /**
     * Retrieves an object from the cache.
     *
     * @param key - The key for the cached object.
     * @returns The cached object or null if not found.
     */
    public getCache(key: string): any | null {
        return this.cache.has(key) ? this.cache.get(key) : null;
    }

    /**
     * Removes an object from the cache.
     *
     * @param key - The key for the cached object.
     */
    public removeCache(key: string): void {
        this.cache.delete(key);
    }

    /**
     * Clears all cached objects.
     */
    public clearCache(): void {
        this.cache.clear();
    }

    /**
     * Checks if an object is in the cache.
     *
     * @param key - The key for the cached object.
     * @returns True if the object is in the cache, false otherwise.
     */
    public hasCache(key: string): boolean {
        return this.cache.has(key);
    }
}

export default CacheAppService;
