import { I2goWorkerInstance } from './I2goWorker';

/* eslint-disable no-use-before-define */
declare global {
    interface Window {
        [key: string]: any;
    }
}

/**
 * Singleton class for managing a Web Worker instance.
 * Ensures that only one instance of the Web Worker is created.
 *
 * @class
 */
export class I2goBaseWorker implements I2goWorkerInstance {
    /**
     * The Web Worker instance.
     *
     * @private
     * @type {Worker}
     */
    private worker: Worker;

    /**
     * The Web Worker instance name.
     *
     * @private
     * @type {string}
     */
    private workerInstanceName: string;

    /**
     * The Web Worker instance operator.
     *
     * @private
     * @type {string}
     */
    private workerExecutorPath: string;

    /**
     * Creates an instance of I2goWorker.
     * If an instance already exists on the window object, it returns that instance instead of creating a new one.
     * Initializes a new Web Worker and attaches an error listener to it.
     *
     * @constructor
     */
    constructor(workerInstanceName: string, workerExecutorPath: string) {
        this.workerInstanceName = workerInstanceName;
        this.workerExecutorPath = workerExecutorPath;

        if (!window[workerInstanceName]) {
            this.worker = new Worker(workerExecutorPath);
            this.worker.addEventListener('error', (e: ErrorEvent) => {
                // eslint-disable-next-line no-console
                console.error('Worker error:', e.message);
            });

            window[workerInstanceName] = this;
        } else {
            this.worker = window[workerInstanceName].getWorker();
        }
    }

    /**
     * Gets the Web Worker instance.
     *
     * @returns {Worker} The Web Worker instance.
     */
    public getWorker = (): Worker => this.worker;

    /**
     * Gets the singleton instance of I2goWorker.
     *
     * @static
     * @returns {I2goWorker} The singleton instance of I2goWorker.
     */
    public getInstance(): I2goBaseWorker {
        return window[this.workerInstanceName];
    }

    /**
     * Sends a message to the worker.
     * @param {any} message - The message to send to the worker.
     */
    public postMessage = (message: { [key: string]: any }): void => {
        this.getWorker().postMessage(JSON.stringify(message));
    };

    /**
     * Adds a message event listener to the worker.
     * @param {(event: MessageEvent) => void} callback - The callback function to handle messages from the worker.
     */
    public subscribeWorker = (callback: (event: MessageEvent) => void): void => {
        this.getWorker().addEventListener('message', callback);
    };
}
