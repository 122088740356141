import { I2goBaseWorker } from './I2goBaseWorker';

/**
 * Specific worker class for modal-related operations.
 * @class
 * @extends I2goBaseWorker
 */
export class I2goModalWorker extends I2goBaseWorker {
    /**
     * Creates an instance of I2goModalWorker.
     * Uses the base class constructor with the specific Web Worker path.
     * @constructor
     */
    constructor() {
        super('i2goModalWorkerInstance', '/static/dashboards_new_theme/js/worker.js');
    }
}
